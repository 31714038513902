<template>
  <v-row class="my-5">
    <div class="main-bg-img"></div>
    <v-col cols="12" sm="10" md="8" lg="6" class="mx-auto text-center">
      <v-card v-if="reservation != null && dataLoaded">
        <v-col cols="12">

          <v-card-title class="green--text">
            <v-icon x-large color="green">mdi-thumb-up</v-icon>
            <br>
            <template v-if="reservation.status == 'OPEN'">Réservation enregistrée</template>
            <template v-if="reservation.status == 'PAID'">Paiement validé</template>
            <template v-if="reservation.status == 'PAIDFEES'">Paiement des frais supplémentaires validé</template>
          </v-card-title>


          <v-divider/>
          <br>
          <div style="padding: 10px 50px;">
          <v-card-text class="font-flower text-left" style="font-size:23px;line-height:30px;">
            <template v-if="reservation.status == 'OPEN'">
              Merci pour votre réservation <span style="text-transform:capitalize">{{ reservation.name }}</span>.
              <br><br>
              Je vous envoie un e-mail très prochainement pour vous indiquer le montant des frais de port applicables pour l'addresse que vous avez indiquée.
              <br><br>
              A très bientôt !
            </template>
            <template v-if="reservation.status == 'PAID' || reservation.status == 'PAIDFEES'">
              Merci de votre paiement <span style="text-transform:capitalize">{{ reservation.name }}</span>.
              <br>je m'occupe personnellement de l'expédition de votre colis au plus vite !
            </template>
          </v-card-text>
          <v-card-text class="font-flower text-right" style="font-size:33px;">
            Nanoug
          </v-card-text>

          
          </div>
          <br>

          <v-divider/>

          <br>
          <v-toolbar color="transparent" elevation="0">
            <v-spacer/>
          <v-btn class="my-2 mx-auto" large color="blue" dark to="/shop">
            <b>Retourner vers la boutique</b>
            <v-icon class="mr-1">mdi-arrow-right</v-icon> 
          </v-btn>
          </v-toolbar>

        </v-col>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios"

export default {
  name: "App",
  components: {
  },
  data: function () {
      return {
        reservation: null,
        formError: false,
        dataLoaded: false
      };
  },
  mounted: async function(){
    await this.reloadEntity()
    this.afterPayement()
  },
  methods: {
      async afterPayement(){
        let action = ''
        if(this.reservation.status == 'OPEN') action = "after-payment-all"
        if(this.reservation.status == 'WAIT_PAYFEES') action = "after-payment-additional-fees"

        console.log('Call /reservation/'+action+'/' + this.$route.params.resaId)
        const { data } = await axios.post('/reservation/'+action+'/' + this.$route.params.resaId)
        this.dataLoaded = true
        
        if(data.error == false){
          this.$store.dispatch('app/openDialogMailDev', data.emailParams)
          await this.reloadEntity()
        }else{
          console.log('Error after /reservation/after-payment/' + this.$route.params.resaId)
          this.formError = true
          //router.push("/thanks/"+this.$route.params.resaId)
        }
      },
      async reloadEntity(){
        let res = await axios.post('/data/query-entities', {
                          entityType: "reservation",
                          query: { _id: this.$route.params.resaId  }
                        })
        if(res.data.error == false){
          this.reservation = res.data.entities[0]
        }
      }
  },
  computed:{
    
  }
};
</script>
